import React from "react";
import SectionTitle from "./SectionTitle";
import Image from "next/image";
import { TwitterTweetEmbed } from "react-twitter-embed";
const Killer3s = () => {
  return (
    <div className="flex flex-col items-center mx-10 ">
      <div className="flex flex-col justify-center items-center space-y-3">
        <SectionTitle title="We bought a basketball team with DAO funds."></SectionTitle>

        <div className="flex flex-col md:flex-row items-center justify-between -mb-4 ">
          <div className="w-[300px] h-[400px] md:w-[500px] md:h-[600px]">
            <TwitterTweetEmbed
              tweetId={"1519672039962935297"}
              options={{ theme: "dark" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Killer3s;
