import React, { FC } from "react";
import { EthereumIcon, PolygonIcon } from "@dustlabs/web/icons";
import { ImageShimmer } from "@shared/components";
import { NftMetadata } from "@deid/types";
import { NftDetailModal } from "@deid/components";

interface Props {
  nftMetadata: NftMetadata;
  tag?: string;
  callback?: () => Promise<void>;
  isPolygon?: boolean;
}

const NftCard: FC<Props> = ({
  nftMetadata,
  tag,
  callback,
  isPolygon,
}: Props) => {
  return (
    <div className="group bg-lightSurface dark:bg-darkSurface relative p-2 rounded-2xl">
      {isPolygon ? (
        <PolygonIcon
          gradient
          className="absolute top-4 right-4 z-10"
          width={20}
          height={20}
        />
      ) : (
        <EthereumIcon
          className="absolute top-4 right-4 z-10"
          width={20}
          height={20}
        />
      )}

      <NftDetailModal
        nftMetadata={nftMetadata}
        className="w-full h-full top-0 left-0"
        callback={callback}
        isPolygon={isPolygon}
      />

      <div className="relative w-full aspect-square overflow-clip rounded-lg">
        <ImageShimmer
          src={nftMetadata.image}
          fallbackImage={nftMetadata.fallbackImage}
          fallbackOverlay="/y00ts/y2084/fallback-overlay.png"
          fill
          alt={`${nftMetadata.id}`}
          className="group-hover:scale-110 transition duration-500"
        />
      </div>
      <div className="flex flex-col pt-2 px-3">
        <div className="min-h-[40px] flex justify-center items-center gap-1">
          <div className="font-bold text-sm dark:text-darkFontPrimary">
            {nftMetadata.name}
          </div>
          {tag && (
            <div className="font-bold text-xs text-fontSecondary dark:text-darkFontSecondary">
              {tag}
            </div>
          )}

          {nftMetadata.rank && (
            <div className="border border-fontSecondary rounded-full pt-[1px] px-2 text-xs text-fontSecondary">
              R: {nftMetadata.rank}
            </div>
          )}
        </div>

        {/* <div className="font-bold text-xs text-fontSecondary">{tag}</div> */}
      </div>
    </div>
  );
};

export default NftCard;
