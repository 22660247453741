type StatProps = {
  title: string;
  data: any;
};

const Stat = ({ title, data }: StatProps) => (
  // bg-hGradient text-transparent bg-clip-text overflow-visible
  <div className="flex flex-col justify-evenly px-4 w-[235px] h-[95px] border border-[#E4E4E4] rounded-lg bg-white dark:bg-black text-center">
    <p className="text-base">{title}</p>
    <p className="text-black text-2xl">{data}</p>
  </div>
);
export default Stat;
