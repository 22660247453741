import { GLOBAL_CONFIG } from "@shared/constants";
import { getAttributeTraitKey } from "@shared/helpers";
import { AttributeMetadata, ProjectConfig } from "@shared/types";
import { Club } from "@clubs/types";
import { AttributesPrice } from "@explorer/types";

/**
 * Check if owned traits meet club requirements
 *
 * @param ownedTraits Array of attribute metadata from NFT metadata
 * @param requirements Array of club requirements from getClubs
 *
 * @returns Boolean of whether owned traits meet club requirements.
 */
export const meetRequirements = (
  ownedTraits: AttributeMetadata[],
  requirements: Club["requirements"]
) => {
  const traitsKeyList = ownedTraits.map(
    (trait: AttributeMetadata) => `${trait.trait_type}_${trait.value}`
  );

  const requirementsKeyList = requirements.map(
    (requirement: Club["requirements"][0]) =>
      `${requirement.traitType}_${requirement.traitValue}`
  );

  return (
    requirementsKeyList.filter((key: string) => traitsKeyList.includes(key))
      .length > 0 ||
    (ownedTraits.length > 0 && requirements.length === 0) // non-trait based
  );
};

/**
 * Converts club requirements to Magic Eden link
 *
 * @param requirements Array of club requirements from getClubs
 * @param attributesPrice Floor price of each attribute
 *
 * @returns Magic Eden link filtered by club traits. Main page if >1 trait type
 */
export const requirementsToLink = (
  requirements: Club["requirements"],
  attributesPrice?: AttributesPrice
) => {
  const { OPENSEA } = GLOBAL_CONFIG as ProjectConfig;

  const processTraitValue = (traitValue: string) =>
    traitValue.replace("24K", "24k").replace("6D", "6d");

  const requirementMap = requirements.reduce(
    (
      acc: {
        [traitType: string]: string[];
      },
      requirement: Club["requirements"][number]
    ) => {
      if (Object.keys(acc).includes(requirement.traitType)) {
        acc[requirement.traitType].push(
          processTraitValue(requirement.traitValue)
        );
        acc[requirement.traitType] = Array.from(
          new Set(acc[requirement.traitType])
        );
      } else {
        acc[requirement.traitType] = [
          processTraitValue(requirement.traitValue),
        ];
      }

      return acc;
    },
    {}
  );

  const mapToQueryString = (map: { [traitType: string]: string[] }) =>
    Object.entries(map).reduce((acc, [key, values], index) => {
      return `${acc}&${values.reduce((acc2, value, index2) => {
        const nameParam = `search[stringTraits][${index}][name]=${key}`;
        const valueParam = `search[stringTraits][${index}][values][${index2}]=${value}`;
        return `${acc2}&${nameParam}&${valueParam}`;
      }, "")}`;
    }, "");

  const linkToLowestPriceAttribute = () => {
    if (!attributesPrice) return OPENSEA;

    const [_, [cheapestTraitType, cheapestTraits]] = Object.entries(
      requirementMap
    ).reduce(
      (
        acc: [number, [string, string[]]],
        [traitType, traits]: [string, string[]]
      ) => {
        let val = acc;
        const traitTypeKey = getAttributeTraitKey(traitType);
        if (!traitTypeKey) return val;
        const traitFloors = attributesPrice[traitTypeKey];
        traits?.forEach((trait) => {
          const traitKey = getAttributeTraitKey(trait);
          const floor = traitFloors?.[traitKey] ?? 0;

          if (floor < acc[0]) {
            val = [floor, [traitType, traits]];
          }
        });
        return val;
      },
      [Number.MAX_SAFE_INTEGER, Object.entries(requirementMap)[0]]
    );

    return `${OPENSEA}?attributes=${mapToQueryString({
      [cheapestTraitType]: cheapestTraits,
    })}`;
  };

  return Object.keys(requirementMap).length > 1
    ? linkToLowestPriceAttribute()
    : `${OPENSEA}?${mapToQueryString(requirementMap)}`;
};

/**
 * Format count to proper display value
 *
 * @param count Integer count of number of members
 *
 * @returns Formatted count string.
 */
export const formatCount = (count: number) => {
  if (count >= 1000000000) {
    return `${(count / 1000000000).toFixed(0)}B`;
  } else if (count >= 1000000) {
    return `${(count / 1000000).toFixed(0)}M`;
  } else if (count >= 10000) {
    return `${(count / 1000).toFixed(0)}K`;
  } else {
    return count.toLocaleString();
  }
};
