import Image from "next/image";

type OriginalArtCardProps = {
  holder: {
    image: string;
    index: number;
  };
};

const OriginalArtCard = ({ holder }: OriginalArtCardProps) => (
    <div className="flex flex-col flex-shrink-0 justify-center items-center border-2 border-black shadow-sm ">
        <Image
            // loader={cloudflareLoader}
            src={holder?.image}
            width={200}
            height={200}
            priority={true}
            className=""
            alt={`original-art-${holder.index}`}
        />
    </div>
)

export default OriginalArtCard;