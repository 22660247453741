import React from "react";
import Container from "../molecules/Container";

type SectionTitleProps = {
  align?: string;
  pretitle?: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
};

export default function SectionTitle(props: SectionTitleProps) {
  return (
    <Container
      className={`flex w-full flex-col mt-4 ${
        props.align === "left" ? "" : "items-center justify-center text-center"
      }`}
    >
      {props.pretitle && (
        <div className="text-[30px] font-bold tracking-wider text-indigo-600 uppercase">
          {props.pretitle}
        </div>
      )}
      <div>
        {props.title && (
          <h2 className="max-w-2xl mt-3  lg:leading-tight text-2xl md:text-3xl">
            {props.title}
          </h2>
        )}

        {props.children && (
          <p className="max-w-2xl text-lg  lg:text-xl xl:text-xl mt-2 text-[#707070] md:w-[485px]">
            {props.children}
          </p>
        )}
      </div>
    </Container>
  );
}
