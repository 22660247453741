import React, { FC, useCallback } from "react";
import { motion } from "framer-motion";
import { useAccount } from "wagmi";
import { Button, CircularProgress } from "@dustlabs/web/core";
import { useDeId } from "@dustlabs/profiles/core";
import { GLOBAL_CONFIG } from "@shared/constants";

interface Props {
  callback?: () => void;
}

const SignInButton: FC<Props> = ({ callback }: Props) => {
  const {
    isConnected,
    isSignedIn,
    signIn,
    isLoadingAuthentication,
    isLoadingProfile,
    holdings,
  } = useDeId();

  const handleSignIn = useCallback(async () => {
    if (!isConnected) return;

    await signIn();
    if (callback) callback();
  }, [signIn, isConnected]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="w-full flex flex-col items-center mt-3"
    >
      {isSignedIn && holdings.length === 0 ? (
        <div className="w-full h-[38px] flex justify-center items-center text-center gap-2">
          {isLoadingProfile ? (
            <>
              <CircularProgress className="first:[&_path]:fill-none fill-fontPrimary" />
              <div>Checking holdings...</div>
            </>
          ) : (
            `de[id] linked to this wallet does not hold ${GLOBAL_CONFIG.NFT_NAME}s!`
          )}
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <Button
            onClick={handleSignIn}
            className="border border-lightFontPrimary text-lightFontPrimary w-[191px] h-[38px] flex justify-center items-center gap-3 !rounded-full disabled:opacity-100"
            disabled={isLoadingAuthentication}
          >
            {isLoadingAuthentication ? (
              <>
                <CircularProgress className="first:[&_path]:fill-none fill-lightFontPrimary" />
                <div>Signing In...</div>
              </>
            ) : (
              <>
                <span>Enter</span>
              </>
            )}
          </Button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default SignInButton;
