import React, { useState } from "react";
import NotableHolderCard from "../molecules/NotableHolderCard";
import MoreImage from "../atoms/MoreImage";

const NotableHolders = () => {
  const [isEntered, setIsEntered] = useState(false);
  return (
    <div className="flex flex-col relative lg:mt-10 lg:w-3/4 lg:mx-auto">
      <h2 className="flex lg:mt-10 text-center self-center text-3xl md:text-4xl">
        Some of our holders are kinda Twitter famous.
      </h2>
      <h3 className="px-4 md:px-0 flex lg:mt-4 text-center self-center text-md md:text-lg text-[#707070]">
        Unrelated note, does anyone have MoonPay’s contact info?
      </h3>
      <div className="relative pt-8 overflow-x-hidden">
        <div className="absolute right-8 top-6">
          <MoreImage />
        </div>
        <div
          className={`flex overflow-x-scroll gap-3 lg:gap-6 mb-4 lg:ml-10 xl:ml-20 py-6 px-10 lg:px-0 ${
            isEntered
              ? "scrollbar-thin scrollbar-thumb-[#5a5a5ab3] scrollbar-thumb-rounded"
              : "scrollbar-none pb-8"
          }`}
          onMouseEnter={() => setIsEntered(true)}
          onMouseLeave={() => setIsEntered(false)}
        >
          {data.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <NotableHolderCard
                  image={item.image}
                  title={item.title}
                  handle={item.handle}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    image: "https://metadata.degods.com/g/3743-dead.png",
    handle: "@aeyakovenko",
    title: "Co-Founder of Solana",
  },
  {
    image: "https://metadata.degods.com/g/5573-dead.png",
    handle: "@rajgokal",
    title: "Co-Founder of Solana",
  },
  {
    image: "https://metadata.degods.com/g/3176-dead.png",
    handle: "@icecube",
    title: "Rapper, actor, and filmmaker",
  },
  {
    image: "https://metadata.degods.com/g/3475-dead.png",
    handle: "@sidazhang",
    title: "Co-Founder & CTO of Magic Eden",
  },
  {
    image: "https://metadata.degods.com/g/7093-dead.png",
    handle: "@chartfumonkey",
    title: "Growth @ Magic Eden",
  },
  {
    image: "https://metadata.degods.com/g/5928-dead.png",
    handle: "@bytheophana",
    title: "Marketing @ Magic Eden",
  },
  {
    image: "https://metadata.degods.com/g/2173-dead.png",
    handle: "@inmortalcrypto",
    title: "XBT and Illiquid JPEG trader",
  },
  {
    image: "https://metadata.degods.com/g/3004-dead.png",
    handle: "@solbigbrain",
    title: "Investor at Big Brain VC",
  },
  {
    image: "https://metadata.degods.com/g/1405-dead.png",
    handle: "@blockchainchad",
    title: "Top JPEG Investor",
  },
  {
    image: "https://metadata.degods.com/g/9230-dead.png",
    handle: "@meta_hess",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/6251-dead.png",
    handle: "@armaniferrante",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/5701-dead.png",
    handle: "@valueandtime",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/5728-dead.png",
    handle: "@WARDELL416",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/6629-dead.png",
    handle: "@sunz8228",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/7464-dead.png",
    handle: "@JeffKwatinetz",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/2217-dead.png",
    handle: "@SCUMSOL",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/2253-dead.png",
    handle: "@sircandyapple",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/2439-dead.png",
    handle: "@notjohnlestudio",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/2310-dead.png",
    handle: "@AussieNftGuy",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/9482-dead.png",
    handle: "@DrHollywood",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/6863-dead.png",
    handle: "PyroNFT",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/3201-dead.png",
    handle: "@ArlieShaban",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/8132-dead.png",
    handle: "@calvinbecerra",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/454-dead.png",
    handle: "@cryptonent",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/1058.png",
    handle: "@SolportTom",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/6295-dead.png",
    handle: "@WillyTheDegen",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/5707-dead.png",
    handle: "@DJohnson_CPA",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/8539-dead.png",
    handle: "@ShooterMcAlpha",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/2333-dead.png",
    handle: "@pimpcapital",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/3555-dead.png",
    handle: "@PixelRainbowNFT",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/1189-dead.png",
    handle: "@PrinceAkeemNFT",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/6267-dead.png",
    handle: "@SpartansHold",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/6730-dead.png",
    handle: "@solanaberries",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/9316-dead.png",
    handle: "sol_unshackled",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/2676-dead.png",
    handle: "@nft_Marty",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/1343-dead.png",
    handle: "@defigemhunt",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/5422-dead.png",
    handle: "@knox_trades",
    title: "",
  },

  {
    image: "https://metadata.degods.com/g/7839-dead.png",
    handle: "@_hcrypto",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/4952-dead.png",
    handle: "@Beanthatgotaway",
    title: "",
  },
  {
    image: "https://metadata.degods.com/g/547-dead.png",
    handle: "@Real_nfty",
    title: "",
  },
];

export default NotableHolders;
