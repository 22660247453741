import React, { useState } from "react";
import Card from "../molecules/OriginalArtCard";
import MoreImage from "../atoms/MoreImage";

const NotableHolders = () => {
  const [isEntered, setIsEntered] = useState(false);
  return (
    <div className="flex flex-col relative lg:w-3/4 lg:mx-auto">
      <h2 className="flex px-4 lg:mt-10 text-center self-center max-w-[700px] text-3xl md:text-4xl">
        We f*cked up and made original art instead of another derivative.
      </h2>

      <button
        onClick={() =>
          window.open(
            "https://twitter.com/SCUMSOL/status/1510352546426077186",
            "_blank",
            "noreferrer"
          )
        }
        className=" self-center mt-6
        rounded bg-white border border-black text-black py-2   w-[184px]"
      >
        {" "}
        Read about the Art
      </button>

      <div className="relative pt-4 overflow-x-hidden">
        <div className="absolute right-8 top-4">
          <MoreImage />
        </div>
        <div
          className={`flex overflow-x-scroll gap-3 lg:gap-6 mb-4 lg:ml-10 xl:ml-20 py-6 px-10 lg:px-0
          ${
            isEntered
              ? "scrollbar-thin scrollbar-thumb-[#5a5a5ab3] scrollbar-thumb-rounded"
              : "scrollbar-none pb-8"
          }`}
          onMouseEnter={() => setIsEntered(true)}
          onMouseLeave={() => setIsEntered(false)}
        >
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <Card
                holder={{
                  image: item.image,
                  index: index,
                }}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    image: "/degods/fire/FTQLS7xXoAIbpbk.jpeg",
  },
  {
    image: "/degods/fire/3491-dead.png",
  },
  {
    image: "/degods/fire/4644-dead.png",
  },
  {
    image: "/degods/fire/7526-dead.png",
  },
  {
    image: "/degods/fire/2115-dead.png",
  },
  {
    image: "/degods/fire/1596-dead.png",
  },
  {
    image: "/degods/fire/1597-dead.png",
  },
  {
    image: "/degods/fire/808-dead.png",
  },
  {
    image: "/degods/fire/6475-dead.png",
  },
];

export default NotableHolders;
