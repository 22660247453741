import React, { FC, useEffect, HTMLAttributes, ReactNode } from "react";
import { Toggle } from "@shared/components";
import { Preview } from "@deid/types";

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: number;
  handleChange: (newValue: unknown) => void;
  options: string[] | ReactNode[];
  highlight?: number;
  omit?: number[];
  disabled?: boolean;
}

const OptionToggle: FC<Props> = ({
  className,
  value,
  handleChange,
  options,
  omit,
  highlight,
  disabled,
  ...componentProps
}) => {
  const handleClick = (newValue: number) => {
    handleChange(newValue);
  };

  useEffect(() => {
    if (omit?.includes(value)) {
      handleChange(value - 1);
    }
  }, [omit, value]);

  return (
    <div
      className={`flex items-center gap-2 p-1 rounded-lg bg-lightSurface border border-lightBorder dark:border-darkBorder ${className} ${
        disabled
          ? "cursor-not-allowed [&_*]:pointer-events-none opacity-50"
          : ""
      }`}
      {...componentProps}
    >
      {options.map((option, index) => (
        <Toggle
          key={index}
          selected={value === index}
          onClick={() => handleClick(index)}
          className="relative flex-1"
        >
          {option}
          {highlight === index && (
            <div className="absolute bottom-0 w-full flex justify-center pl-[2px]">
              <div className="w-1 h-1 bg-darkSurface rounded-full" />
            </div>
          )}
        </Toggle>
      ))}
    </div>
  );
};
export default OptionToggle;
