import React from "react";
import { useDeId } from "@dustlabs/profiles/core";
import { LandingView, MyNftsView } from "@deid/components";
import { ClientOnly } from "@shared/components";
import { useWindowSize } from "@dustlabs/web/hooks";

const DeIdView = () => {
  const { isSignedIn } = useDeId();
  const [winWidth, winHeight] = useWindowSize();

  return (
    <ClientOnly
      className={`w-full ${isSignedIn ? "lg:pt-[77px] px-5 lg:px-24" : ""}`}
      style={{ height: winWidth < 1024 ? winHeight - 73 : winHeight }}
    >
      {isSignedIn ? <MyNftsView /> : <LandingView />}
    </ClientOnly>
  );
};

export default DeIdView;
