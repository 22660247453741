import React, { useState } from "react";
import SectionTitle from "./SectionTitle";
import MoreImage from "../atoms/MoreImage";
import NotableHolderCard from "../molecules/NotableHolderCard";
import Stat from "../molecules/Stat";
import { getClubs } from "@clubs/helpers";
import { Club } from "@clubs/types";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useRouter } from "next/router";

const Alumni = () => {
  const router = useRouter();
  const [isEntered, setIsEntered] = useState(false);
  const { data: clubsList } = useQuery<Club[]>(
    ["clubs"],
    async () => {
      try {
        const data = await getClubs("");
        return [
          ...data.filter((club: Club) => club.metRequirements),
          ...data.filter((club: Club) => !club.metRequirements),
        ];
      } catch (e) {
        return [];
      }
    },
    {
      enabled: true,
      onError: (e) => {
        console.error(`Fetch Clubs Error: ${(e as Error).message}`);
        toast.error((e as Error).message);
      },
    }
  );
  const getOrderedClubsList = () => {
    let orderedClubs: Array<Club> = [];
    if (clubsList && clubsList.length > 0) {
      const remainingClubs = clubsList.filter((club: Club) => {
        if (
          club.name !== "X+" &&
          club.name !== "Mickey DeGods" &&
          club.name !== "DeShoeys" &&
          club.name !== "DeStables"
        ) {
          return true
        } else {
          orderedClubs.push(club);
          return false
        }
      });

      orderedClubs.push(
        ...remainingClubs
      );

      return orderedClubs;
    }
    return orderedClubs;
  };

  return (
    <div className="relative flex flex-col lg:flex-row justify-between ml-[2%] lg:ml-[10%] lg:gap-20">
      {/* labels + stats */}
      <div className="flex flex-col lg:max-w-[500px]">
        <SectionTitle
          title="DeGods Clubs"
          className="items-center justify-center lg:items-start lg:justify-start"
        >
          We have dozens of active clubs within our greater community. Members
          have gone off to create successful projects, business, collections and
          DAO’s. Clubs give members a space to create and grow the DeGods
          ecosystem.
        </SectionTitle>
        <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-4 lg:mt-10 mb-10">
          <Stat title="Total Clubs" data={clubsList?.length ?? 0} />
        </div>
      </div>
      <div className="relative overflow-hidden">
        {/* absolute positioned "more" tag */}
        <div className="absolute right-8  lg:top-16 z-20">
          <MoreImage />
        </div>
        {/* alumni cards */}
        <div
          className={`flex overflow-x-scroll  gap-6 lg:mt-20 lg:mb-20 py-10 ml-8 px-2 lg:pt-8
          ${
            isEntered
              ? "scrollbar-thin scrollbar-thumb-[#5a5a5ab3] scrollbar-thumb-rounded"
              : "scrollbar-none pb-12"
          }`}
          onMouseEnter={() => setIsEntered(true)}
          onMouseLeave={() => setIsEntered(false)}
        >
          {getOrderedClubsList().map((item: Club, index: number) => (
            <React.Fragment key={index}>
              <NotableHolderCard
                image={item?.imageUrl}
                title={item?.name}
                alumni
                onClick={() => router.push(`/clubs/${item.slug}`)}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Alumni;
