import type { NextPage } from "next";
import { ClientOnly, PageTemplate } from "@shared/components";
import { GLOBAL_CONFIG } from "@shared/constants";
import { ProjectConfig } from "@shared/types";
import {
  DeGodsLandingView,
  LandingView,
  DeGodsIIIView,
} from "@landing/components";
import { LandingView as MainnetY00tsLanding } from "@deid/components";
import { useRouter } from "next/router";

const Landing: NextPage = () => {
  const router = useRouter();
  const { HEAD, ORG_NAME, SEASON } = GLOBAL_CONFIG as ProjectConfig;

  const getSeasonSkin = () => {
    switch (ORG_NAME) {
      case "y00ts":
        switch (SEASON) {
          case 2:
            return {
              forceCenter: true,
              landing: (
                <ClientOnly className="w-full h-full">
                  <MainnetY00tsLanding
                    callback={() => router.push("/holdings")}
                  />
                </ClientOnly>
              ),
              footer: undefined,
            };
          case 1:
          default:
            return {
              forceCenter: false,
              landing: <LandingView />,
              footer: 1,
            };
        }

      case "DeGods":
      default:
        switch (SEASON) {
          case 3:
            return {
              forceCenter: true,
              landing: <DeGodsIIIView />,
              footer: undefined,
            };
          case 2:
          case 1:
          default:
            return {
              forceCenter: false,
              landing: <DeGodsLandingView />,
              footer: undefined,
            };
        }
    }
  };

  const { forceCenter, landing, footer } = getSeasonSkin();

  return (
    <PageTemplate
      pageMeta={HEAD}
      path="/"
      forceCenter={forceCenter}
      footer={footer}
    >
      {landing}
    </PageTemplate>
  );
};

export default Landing;
