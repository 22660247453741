import axios from "axios";
import toast from "react-hot-toast";
import { UseQueryOptions, useMutation, useQueries } from "react-query";
import { useDeId } from "@dustlabs/profiles/core";
import { ProfileHeldCollection } from "@dustlabs/profiles/types";
import { NftMetadata } from "@deid/types";
import { METADATA_API } from "@deid/constants";
import { useMemo } from "react";

/**
 * Hook that fetches owned NFT tokenIds across all user's wallet addresses.
 *
 * @returns React useQueries response object.
 */
export const useNftMetadata = () => {
  const { holdings } = useDeId();

  const evmHoldings = useMemo(
    () =>
      holdings
        .find(
          (heldCollection: ProfileHeldCollection) =>
            heldCollection.contract === process.env.NEXT_PUBLIC_NFT_CONTRACT
        )
        ?.tokens.map((token) => token.tokenId) ?? [],
    [holdings]
  );

  const polygonHoldings = useMemo(
    () =>
      holdings
        .find(
          (heldCollection: ProfileHeldCollection) =>
            heldCollection.contract ===
            process.env.NEXT_PUBLIC_POLYGON_NFT_CONTRACT
        )
        ?.tokens.map((token) => token.tokenId) ?? [],
    [holdings]
  );

  const fetchNftMetadata = async (metadataUrl: string) => {
    const response = await axios.get(metadataUrl);
    const metadata = response.data;
    metadata["id"] = Number(metadata.name.split("#")[1] - 1);
    return metadata;
  };

  const queries: UseQueryOptions<NftMetadata, Error>[] =
    [...evmHoldings, ...polygonHoldings]?.map((tokenId: number) => ({
      queryKey: ["getTokenMetadata", tokenId],
      queryFn: () =>
        fetchNftMetadata(
          process.env.NEXT_PUBLIC_METADATA_URL + tokenId.toString() + ".json"
        ),
      enabled: evmHoldings.length > 0 || polygonHoldings.length > 0,
    })) ?? [];

  return useQueries(queries);
};

export interface ApplyMetadataPayload {
  tokenId: number;
  perspective: number;
}

export const useApplyMetadata = (callback?: () => Promise<void>) => {
  const { authAccess } = useDeId();

  const applyMetadata = authAccess(
    async ({ accessToken }, payload: ApplyMetadataPayload) => {
      try {
        const response = await axios.post(
          `${METADATA_API}/updatePFP`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (callback) await callback();
        toast.success(response.data.message);

        return response.data;
      } catch (error) {
        toast.error((error as Error).message);
        console.error(error);
      }
    }
  );

  return useMutation(["handleApplyMetadata"], applyMetadata);
};
