import React from "react";
import Image from "next/image";
import Container from "../molecules/Container";
import Link from "next/link";

const TranscendPanel = () => {
  return (
    <Container className="-mt-8">
      <div className="border border-black flex flex-col-reverse md:flex-row items-center justify-between w-full max-w-4xl gap-5 mx-auto  bg-white px-6 py-7 md:py-4  lg:px-10  lg:flex-nowrap rounded-xl">
        <div className="flex-grow text-center md:text-left">
          <h2 className="text-2xl font-medium lg:text-3xl  text-black overflow-visible">
            Transcend Your DeGod.
          </h2>
          <p className="my-2 font-medium text-black text-opacity-90 text-md mt-5 mb-5">
            DeadGods are a fine art upgrade of the original DeGods and they also
            can claim a t00b.
          </p>
          <Link
            href="https://deadgods.com/"
            target="_blank"
            className="hidden md:flex text-[#6100FF] max-w-fit"
          >
            Transcend
          </Link>
          <a className="md:hidden  disabled text-degodPurple">
            Access with your PC
          </a>
        </div>
        <div className="flex-shrink-0 w-full text-center md:w-auto">
          <div className="flex-shrink-0 w-full lg:text-left md:w-auto my-[10px]">
            <div className="cursor-pointer transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-300  ">
              <a
                href="https://www.deadgods.com/"
                target="_blank"
                rel="noreferrer"
                className="hidden md:block"
              >
                <Image
                  src={"/degods/transcend-degod-image.png"}
                  width={139}
                  height={139}
                  priority
                  alt="transcend"
                  className=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default TranscendPanel;
