import { useState, useEffect } from "react";
import Image from "next/image";

type PageViewProps = {
  hideBottom?: boolean;
  children: React.ReactNode;
};

const PageView = ({ hideBottom, children }: PageViewProps) => {
  const [mounted, setMounted] = useState(false);

  // When mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);

  if (!mounted) return null;

  return (
    <div className="flex flex-col justify-between">
      {/* paper top image */}
      <div className="relative">
        <Image
          src="/degods/paper_top_light.png"
          width={2161}
          height={132}
          alt="Paper Top"
          className="w-screen -mb-1"
        />
      </div>

      {/* content */}
      <div className="dark:bg-[#262626] bg-[#F8F9FA] relative">{children}</div>

      {/* paper bottom image */}
      {!hideBottom && (
        <div className="relative mb-10 lg:mb-20">
          <Image
            src="/degods/paper_bottom_light.png"
            width={2161}
            height={132}
            alt="Paper Bottom"
            className="w-screen"
          />
        </div>
      )}
    </div>
  );
};
export default PageView;
