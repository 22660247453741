import React, { useEffect } from "react";
//components
import Footer from "../organisms/Footer";
import Home from "../organisms/Home";
import TranscendPanel from "../organisms/TranscendPanel";
import BuyPanel from "../organisms/BuyPanel";
import NotableHolders from "../organisms/NotableHolders";
//shared components
import PageView from "../organisms/PageView";
import Alumni from "../organisms/Alumni";
import OriginalArt from "../organisms/OriginalArt";
import Killer3s from "../organisms/Killer3s";
import SectionTitle from "../organisms/SectionTitle";
import Image from "next/image";

const DeGodsLandingView = () => {
  return (
    <div className="overflow-x-hidden mt-[15vh] md:mt-24">
      <Home />

      <PageView hideBottom={false}>
        <div className="mb-32 lg:my-20" />
        <OriginalArt />
        <div className="my-20" />
        <NotableHolders />
        <div className="my-20" />
      </PageView>

      <div className="my-10 " />
      <Killer3s />
      <div className="my-10 " />
      <div className="flex flex-col items-center mx-10 mb-10">
        <div className="flex flex-col justify-center items-center space-y-3">
          <SectionTitle title="Our community is global - Every city in the world" />
          <div className="flex flex-col md:flex-row items-center justify-between -mb-4 ">
            <div className="">
              <Image
                src="/degods/global-community-image.png"
                height={346}
                width={731.9}
                alt="degods map"
              />
            </div>
          </div>
        </div>
      </div>

      <Alumni />

      <div className="my-20 " />
      <PageView hideBottom={false}>
        <div className="my-20 " />
        <TranscendPanel />
        <BuyPanel />
      </PageView>
      <div className="my-8 md:my-20" />
      <p className="w-auto text-center  text-xl md:text-2xl pb-16 md:pb-32">
        We tried some shit. <br />
        We learned some shit. <br />
        Now we&apos;re trying some new shit.
      </p>
      <Footer />
    </div>
  );
};

export default DeGodsLandingView;
