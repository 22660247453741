import axios, { AxiosRequestConfig } from "axios";
import { GLOBAL_CONFIG } from "@shared/constants";
import { ExistingClub, TwitterData } from "@clubs/types";

/**
 * Creates club
 *
 * @param jwt JWT token from twitter authentication
 *
 * @returns New club data on success
 */
export const createClub = async (formData: FormData, jwt: string) => {
  const config: AxiosRequestConfig = {
    url: `${GLOBAL_CONFIG.API_URL}/clubs`,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "multipart/form-data",
    },
    method: "POST",
    data: formData,
  };

  return await axios.request(config).then((res) => {
    const data = res.data;
    return data;
  });
  return null;
};

/**
 * Fetch club by url slug
 *
 * @param slug Club's url slug
 *
 * @returns Club details object
 */
export const getClubDetails = async (slug: string) => {
  return await axios
    .get(`${GLOBAL_CONFIG.API_URL}/clubs/${slug}`)
    .then((res) => {
      return res.data.club;
    })
    .catch((err) => {
      console.error("Error getting club details ", err.message);
    });
};

/**
 * Fetch all clubs
 *
 * @returns Array of clubs.
 */
export const getClubs = async (search: string) => {
  return await axios
    .get(`${GLOBAL_CONFIG.API_URL}/clubs`, { params: { name: search } })
    .then((res) => {
      return res.data.clubs;
    })
    .catch((err) => {
      console.error("Error getting clubs ", err.message);
    });
};

/**
 * Fetch club by public key
 *
 * @param publicKey User's public key
 *
 * @returns Existing club object.
 */
export const getExistingClub = async (publicKey: string) => {
  return await axios
    .get(`${GLOBAL_CONFIG.API_URL}/users/${publicKey}/clubs`)
    .then((res) => {
      return res.data.club as ExistingClub;
    });
};

/**
 * Fetch twitter data
 *
 * @param code Code from Twitter auth redirect
 *
 * @returns Object containing Twitter information.
 */
export const getTwitterData = async (code: string) => {
  const config: AxiosRequestConfig = {
    url: `${GLOBAL_CONFIG.API_URL}/twitter/data?code=${code}`,
    withCredentials: true,
    responseType: "json",
    method: "GET",
  };

  return await axios
    .request(config)
    .then((res) => {
      const data = res.data;
      return data as TwitterData;
    })
    .catch((err) => {
      console.error("Error getting Twitter data ", err.message);
    });
};
