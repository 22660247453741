import React from "react";
import { DeGodsLogo } from "@dustlabs/web/icons";
import { BlurButton, LoadingDots } from "@shared/components";
import { GLOBAL_CONFIG } from "@shared/constants";

const DeGodsIIIView = () => {
  return (
    <div className="relative items-center">
      <div
        className="w-full flex flex-col gap-7 items-center cursor-pointer"
        onClick={() =>
          window.open(
            GLOBAL_CONFIG.PARENT_SITE_URL,
            "_blank",
            "noopener noreferrer"
          )
        }
      >
        <DeGodsLogo
          variant="season3"
          className="w-[250.5px] h-[166.5px] md:w-[501px] md:h-[333px]"
        />
        <LoadingDots className="ml-3 w-[107px] text-2xl">Loading</LoadingDots>
      </div>

      <div className="absolute w-full flex justify-center -bottom-36">
        <BlurButton />
      </div>
    </div>
  );
};

export default DeGodsIIIView;
