import React, { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { Button, Drawer, Modal, ModalProps } from "@dustlabs/web/core";
import {
  CloseIcon,
  DeGodsLogo,
  DownloadIcon,
  Y00tsLogo,
} from "@dustlabs/web/icons";
import { useBreakpoint } from "@shared/hooks";
import Image from "next/image";
import Link from "next/link";
import { ImageShimmer } from "@shared/components";

interface Props extends ModalProps {}

const TakeoverModal: FC<Props> = ({ ...componentProps }: Props) => {
  const isWeb = useBreakpoint("lg");
  const router = useRouter();

  const renderContent = () => (
    <div className="w-full h-full max-h-[85vh] pt-6 pb-6 lg:p-12 overflow-scroll scrollbar-none">
      <div className="flex flex-col items-center gap-2 pt-6 lg:pt-0 pb-8">
        <div className="flex justify-center items-center gap-3">
          <Y00tsLogo width={34} height={47} />
          <span className="text-3xl font-header">x</span>
          <Image
            src="/y00ts/y2084/smile-club.png"
            width={48}
            height={48}
            alt="takeover"
          />
        </div>

        <div className="text-xs text-fontSecondary">
          by&nbsp;
          <Link
            href="https://twitter.com/smileclub_NFT"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            @smileclub_NFT
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center divide-y md:divide-x md:divide-y-0 divide-lightBorder">
        <div className="flex flex-col items-center px-6 pb-6 md:pb-0">
          <div className="flex flex-col items-center gap-3 pb-5">
            <div className="h-[230px] md:h-[262px] flex items-center">
              <ImageShimmer
                priority
                src="/y00ts/y2084/y2084-traits.png"
                width={isWeb ? 200 : 156}
                height={isWeb ? 220 : 208}
                alt="poster"
              />
            </div>

            <div className="pl-3 max-w-[311px] text-center lg:text-left">
              <div className="font-header pb-2">y2084 traits</div>
              <div className="text-xs">
                Alternative versions of the y00ts collection in the style of
                y00topia 2084 are now available.
              </div>
            </div>
          </div>

          <Button
            className="w-[243px] h-[35px] flex flex-row justify-center items-center bg-lightSurface border border-primary rounded-lg"
            onClick={() => {
              router.push("/holdings");
              componentProps.handleClose();
            }}
          >
            <div className="text-sm font-bold text-primary">
              See my y00ts in 2084
            </div>
          </Button>
        </div>

        <div className="flex flex-col items-center px-6">
          <div className="flex flex-col items-center gap-3 pb-5">
            <div className="h-[220px] md:h-[262px] flex items-center lg:items-start pt-2">
              <ImageShimmer
                priority
                src="/y00ts/y2084/stack.png"
                width={isWeb ? 201 : 156}
                height={isWeb ? 205 : 208}
                alt="poster"
              />
            </div>

            <div className="pl-3 max-w-[311px] text-center lg:text-left">
              <div className="font-header pb-2">y00topia 2084</div>
              <div className="text-xs">
                Check out the y00topia 2084 introduction Comic Book, illustrated
                by Smile Club.
              </div>
            </div>
          </div>

          <Link
            className="w-[288px] h-[35px] flex flex-row justify-center items-center bg-lightSurface border border-primary rounded-lg"
            href="https://static.y00ts.com/y2084/comic.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="text-sm font-bold text-primary">
              Read the y00topia 2084 Comic Book
            </div>
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isWeb ? (
        <Modal
          initial={{ opacity: 0, y: "50vh" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "50vh" }}
          transition={{ duration: 0.35 }}
          className="max-w-[754px] !w-[95vw] max-h-[759px] !h-fit !rounded-xl !bg-lightBackground !p-0 flex"
          closeIconProps={{ className: "hidden" }}
          backdropProps={{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: { duration: 0.35 },
            className: "bg-opacity-50 bg-[#000]",
          }}
          {...componentProps}
        >
          <motion.div
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className="absolute -top-3 -right-3 cursor-pointer z-30 w-8 h-8 !rounded-full bg-[#19191C] dark:border dark:border-darkBorder flex justify-center items-center"
            onClick={componentProps.handleClose}
          >
            <CloseIcon width={10} height={10} className="fill-[#D2D2D2]" />
          </motion.div>

          {renderContent()}
        </Modal>
      ) : (
        <Drawer
          anchor="bottom"
          className="relative max-h-[85vh] heightSmall:max-h-[90vh] !h-fit !bg-lightBackground 
        flex justify-center justify-self-center rounded-t-2xl !pt-0"
          closeIconProps={{
            className: "hidden",
          }}
          backdropProps={{
            className: "bg-opacity-50 bg-[#000]",
          }}
          {...componentProps}
        >
          <div className="absolute w-full -top-4 lg:-top-3 flex justify-center lg:justify-end items-center z-20">
            <Button
              text
              onClick={componentProps.handleClose}
              className="relative lg:absolute w-8 h-8 right-auto lg:-right-3 !rounded-full bg-[#19191C] dark:border dark:border-darkBorder flex justify-center items-center"
            >
              <CloseIcon width={10} height={10} className="fill-[#D2D2D2]" />
            </Button>
          </div>
          {renderContent()}
        </Drawer>
      )}
    </>
  );
};

export default TakeoverModal;
