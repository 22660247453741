import React from "react";
import Container from "../molecules/Container";
import { BlurButton } from "@shared/components";

const BuyPanel = () => {
  return (
    <Container className="-mt-8">
      <div className="border border-black flex  flex-col md:flex-row items-center justify-between w-full max-w-4xl gap-5 mx-auto text-white bg-white px-7 py-3 lg:px-12  lg:flex-nowrap rounded-xl">
        <div className="flex-grow text-center md:text-left">
          <h2 className="text-2xl font-medium lg:text-3xl text-black mt-2 pt-4">
            Buy A DeGod
          </h2>
          <p className="font-medium text-black text-opacity-90 lg:text-md mt-5 lg:mb-5 lg:pr-10">
            DeGods has become one of the top NFT collections by continually
            providing value and utility back to our community in fun, exciting,
            and innovative ways.
          </p>
        </div>
        <div className="flex-shrink-0 w-fit mx-auto text-center md:w-auto mb-2">
          <BlurButton />
        </div>
      </div>
    </Container>
  );
};
export default BuyPanel;
