import Image from "next/image";

type NotableHolderCardProps = {
  image: string;
  title: string;
  handle?: string;
  alumni?: boolean;
  onClick?: () => void;
};

const NotableHolderCard = ({
  image,
  title,
  handle,
  alumni,
  onClick,
}: NotableHolderCardProps) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      className="flex flex-col flex-shrink-0 space-y-2 cursor-pointer"
    >
      <div className="h-[150px] self-center">
        <Image
          src={image}
          width={alumni ? 200 : 150}
          height={alumni ? 200 : 150}
          loading="eager"
          className={`${alumni ? "rounded-lg" : "border-2 border-black"}`}
          alt={title}
        />
      </div>
      <p className="text-center text-[#6100FF] text-sm">{handle}</p>
      <p className="mt-2 font-medium text-black dark:text-white text-opacity-90 self-center text-sm w-[150px] text-center">
        {title}
      </p>
    </div>
  );
};

export default NotableHolderCard;
