import React, { FC, HTMLAttributes } from "react";
import Image from "next/image";
import { GLOBAL_CONFIG } from "@shared/constants";
import { NftMetadata } from "@deid/types";

interface Props extends HTMLAttributes<HTMLDivElement> {
  nftMetadata: NftMetadata;
}

const NftName: FC<Props> = ({
  nftMetadata,
  className,
  ...componentProps
}: Props) => {
  const { REWARD_NAME, REWARD_ICON, ASSETS } = GLOBAL_CONFIG;

  return (
    <div
      className={`w-full flex justify-between items-center py-3 px-2 rounded-lg ${className}`}
      {...componentProps}
    >
      <div className="flex flex-col">
        <div className="font-bold text-2xl font-header">{nftMetadata.name}</div>
        {/* <div className="flex items-center gap-1">
          <p>{`${REWARD_NAME}s: `}</p>
          <Image
            src={`${ASSETS}${REWARD_ICON}`}
            width={13}
            height={14.69}
            alt="reward icon"
          />
          <p>{nftMetadata.points.amount}</p>
        </div> */}
      </div>
      {nftMetadata.rank && (
        <div className="border border-fontSecondary rounded-full py-1 px-3 text-xs text-fontSecondary">
          Rank #{nftMetadata.rank}
        </div>
      )}
    </div>
  );
};

export default NftName;
