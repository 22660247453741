import React, { FC, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
import { useAccount } from "wagmi";
import { Button } from "@dustlabs/web/core";
import { SignInButton, TakeoverModal } from "@deid/components";
import { ConnectButton } from "@shared/components";
import { GLOBAL_CONFIG } from "@shared/constants";
import { InfoIcon, Y00tsLogo } from "@dustlabs/web/icons";

// const imageSet = [
//   {
//     imageUrl: "/y00ts/y2084/landing/1.png",
//     logoColor: "#4671DF",
//     bgColor: "#EFECE7",
//   },
//   {
//     imageUrl: "/y00ts/y2084/landing/2.png",
//     logoColor: "#2F2F2F",
//     bgColor: "#F1F0EB",
//   },
//   {
//     imageUrl: "/y00ts/y2084/landing/3.png",
//     logoColor: "#00000",
//     bgColor: "#E2EAEC",
//   },
//   {
//     imageUrl: "/y00ts/y2084/landing/4.png",
//     logoColor: "#000000",
//     bgColor: "#DCE4D7",
//   },
//   {
//     imageUrl: "/y00ts/y2084/landing/5.png",
//     logoColor: "#5C7FF8",
//     bgColor: "#F3E1D7",
//   },
//   {
//     imageUrl: "/y00ts/y2084/landing/6.png",
//     logoColor: "#3076EC",
//     bgColor: "#F3EBD6",
//   },
// ];

const imageSet = [
  {
    imageUrl: "/y00ts/landing/1.png",
    logoColor: "#4671DF",
    bgColor: "#DDE5D8",
  },
  {
    imageUrl: "/y00ts/landing/2.png",
    logoColor: "#2F2F2F",
    bgColor: "#EEE2CC",
  },
  {
    imageUrl: "/y00ts/landing/3.png",
    logoColor: "#00000",
    bgColor: "#F3E0D6",
  },
  {
    imageUrl: "/y00ts/landing/4.png",
    logoColor: "#000000",
    bgColor: "#F2F0EB",
  },
  {
    imageUrl: "/y00ts/landing/5.png",
    logoColor: "#5C7FF8",
    bgColor: "#F1F0E2",
  },
  {
    imageUrl: "/y00ts/landing/6.png",
    logoColor: "#3076EC",
    bgColor: "#F3E0D6",
  },
  {
    imageUrl: "/y00ts/landing/7.png",
    logoColor: "#4671DF",
    bgColor: "#F1F0E2",
  },
  {
    imageUrl: "/y00ts/landing/8.png",
    logoColor: "#000000",
    bgColor: "#F2F0EB",
  },
  {
    imageUrl: "/y00ts/landing/9.png",
    logoColor: "#FF8FBE",
    bgColor: "#F1F0E2",
  },

  {
    imageUrl: "/y00ts/landing/10.png",
    logoColor: "#FF0026",
    bgColor: "#F3E0D6",
  },
  {
    imageUrl: "/y00ts/landing/11.png",
    logoColor: "#EEAA6D",
    bgColor: "#E4E9ED",
  },
  {
    imageUrl: "/y00ts/landing/12.png",
    logoColor: "#FF0000",
    bgColor: "#EFE1CE",
  },
];

interface Props {
  callback?: () => void;
}

const LandingView: FC<Props> = ({ callback }: Props) => {
  const { isConnected } = useAccount();
  // const [randomImage, setRandomImage] = useState<number>(
  //   Math.floor(Math.random() * imageSet.length)
  // );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRandomImage(Math.floor(Math.random() * imageSet.length));
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  const { imageUrl, logoColor, bgColor } = useMemo(
    () => imageSet[Math.floor(Math.random() * imageSet.length)],
    []
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const shownBefore = localStorage.getItem("y2084-takeover-shown");

    if (!shownBefore) {
      localStorage.setItem("y2084-takeover-shown", "true");
      setTimeout(() => setModalOpen(true), 1500);
    }
  }, []);

  return (
    <motion.div
      {...GLOBAL_CONFIG.OPACITY_ANIMATION}
      className="fixed top-0 w-full h-full flex flex-col justify-center items-center"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="flex flex-col items-center gap-5 md:gap-10 mb-12 heightSmall:mb-24 md:mb-52 z-10">
        <Y00tsLogo
          variant="full"
          className="w-[80vw] max-w-[500px] h-fit"
          style={{ fill: logoColor }}
        />
        {/* <Button
          text
          onClick={handleOpen}
          className="relative w-[80vw] max-w-[500px] mb-3 lg:mb-0"
          style={{ aspectRatio: 2.5 }}
        >
          <Image src="/y00ts/y2084/landing.png" fill alt="landing" />
        </Button> */}

        {/* <div className="flex flex-col items-center w-[191px]">
          <ConnectButton
            variant="small"
            className="w-full [&_.profileButton]:w-full [&_.profileButtonRoot]:w-full [&_.profileButtonMenuRoot]:w-full"
          />
          {isConnected ? (
            <SignInButton callback={callback} />
          ) : (
            <div className="h-[38px] mt-3" />
          )}
        </div> */}
      </div>

      <div
        className="absolute bottom-0 z-0 w-[85vw] lg:w-[50vw] heightSmall:max-w-[500px] max-w-[800px]"
        style={{ aspectRatio: 2 }}
      >
        <Image src={imageUrl} alt={`${imageUrl}`} fill />
      </div>

      {/* <div className="absolute top-[88px] lg:top-auto lg:bottom-8 right-5 lg:right-16">
        <Button text onClick={handleOpen} className="flex items-center gap-2">
          <Image
            src="/y00ts/y2084/smile-club.png"
            width={34}
            height={34}
            alt="takeover"
          />
          <div className="font-header">takeover by smileclub</div>
        </Button>
      </div>

      <TakeoverModal isOpen={modalOpen} handleClose={handleClose} /> */}
    </motion.div>
  );
};

export default LandingView;
